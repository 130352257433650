import React from 'react'
import styled from 'styled-components'
import {Span, Title2} from '../components/UI'
import { breakpoints } from '../utils/helper'
import Cross from '../svg/cross-red.svg'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.colors.black};
    padding: 94px 30px 30px;    
    align-items: flex-start;
    margin: 0 0 -64px;
    z-index: 100;
    position: relative;
    
    h1 {
        color: ${props => props.theme.colors.red};
        margin-bottom: 12px;
    }
    
    span {
        color: ${props => props.theme.colors.white};
        margin-bottom: 36px;
    }
    
    @media ${breakpoints.desktop} {
        margin: 0;
        padding: 60px 100px;
        margin: 90px 0 -90px;
    }
`

const CopyBox = styled.div`
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
  margin: 0 auto;
  @media ${breakpoints.desktop} {
    max-width: 1000px;
  }
    
    button {
        display: flex;
        align-items: center;
        svg {
            margin-right: 6px;
        }
    }
`

const Title = styled(Title2)`
    color: ${props => props.theme.colors.red};
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`

const Disclaimer = ({title, body, buttonTitle, toggle, children}) => {
    //const [showDisclaimer, setShowDisclaimer] = useLocalStorage(storageId, true);

    return (
        <Container>
            <CopyBox>
                <Row>
                    <Title>{title}</Title>
                    <Cross onClick={toggle}/>
                </Row>

                {children}

            </CopyBox>
        </Container>
    )
}


export default Disclaimer
